"use client";

import { RightOutlined } from "@ant-design/icons";
import { Button, Tabs, TabsProps } from "antd";
import Image from "next/image";
import Link from "next/link";
import React from "react";
import { useTranslation } from "react-i18next";
import { NotifyButton } from "./NotifyMeButton";
export function LandingIPhone16() {
  const items: TabsProps["items"] = [{
    children: <div className="text-center">
          <Image alt="iPhone 16" height={500} src="/img/offers/Landingpage_offer_iPhone_16_Series_Bank_Online.png" width={1500} />
          <span className="block text-balance">
            *อัตราดอกเบี้ย 0%
            เมื่อชำระยอดผ่อนตามที่เรียกเก็บรายเดือนภายในวันครบกำหนดชำระ <br />
            ใช้เท่าที่จำเป็นและชำระคืนได้เต็มจำนวนตามกำหนด จะได้ไม่เสียดอกเบี้ย
            16% ต่อปี <br /> กู้เท่าที่จำเป็นและชำระคืนได้ตามกำหนด
            จะได้ไม่เสียดอกเบี้ย 20% - 25% ต่อปี
          </span>
          <Link className="mx-auto mt-6 inline-block text-black" href="https://www.uficon.com/bankpromotion-online" target="_blank">
            <Button className="mx-auto h-auto bg-black px-4 py-2 text-white">
              อ่านรายละเอียดเพิ่มเติมคลิก <RightOutlined />
            </Button>
          </Link>
        </div>,
    key: "1",
    label: "ออนไลน์"
  }, {
    children: <div className="text-center">
          <Image alt="iPhone 16" height={500} src="/img/offers/Landingpage_offer_iPhone_16_Series_Bank_Offline.png" width={1500} />
          <span className="block text-balance">
            *อัตราดอกเบี้ย 0%
            เมื่อชำระยอดผ่อนตามที่เรียกเก็บรายเดือนภายในวันครบกำหนดชำระ <br />
            ใช้เท่าที่จำเป็นและชำระคืนได้เต็มจำนวนตามกำหนด จะได้ไม่เสียดอกเบี้ย
            16% ต่อปี <br /> กู้เท่าที่จำเป็นและชำระคืนได้ตามกำหนด
            จะได้ไม่เสียดอกเบี้ย 20% - 25% ต่อปี
          </span>
          <Link className="mx-auto mt-6 inline-block text-black" href="https://www.uficon.com/bankpromotion-retail" target="_blank">
            <Button className="mx-auto h-auto bg-black px-4 py-2 text-white">
              อ่านรายละเอียดเพิ่มเติมคลิก <RightOutlined />
            </Button>
          </Link>
        </div>,
    key: "2",
    label: "หน้าร้าน"
  }];
  return <>
      <ProductOne data-sentry-element="ProductOne" data-sentry-source-file="LandingIPhone16.tsx" />
      <ProductTwo data-sentry-element="ProductTwo" data-sentry-source-file="LandingIPhone16.tsx" />
      <div>
        <div className="my-4">
          <h4 className="mb-2 text-center text-2xl font-bold leading-tight md:text-4xl">
            พิเศษสำหรับลูกค้า Pre-Order เท่านั้น
          </h4>
          <span className="block text-center text-lg md:text-2xl">
            เมื่อซื้อ iPhone 16 Series รุ่นใดก็ได้
          </span>
        </div>
        <span className="block text-center text-lg">
          ผ่านช่องทางเว็บไซต์{" "}
          <span className="underline">pre-order.uficon.com</span> <br /> หรือ
          หน้าร้าน iStudio by UFicon, <br className="md:hidden" /> U•Store by
          UFicon ทุกสาขา
        </span>
        <Image alt="iPhone 16" height={500} src="/img/offers/Landingpage_offer_iPhone_16_Series_premiumgift_01.png" width={1500} data-sentry-element="Image" data-sentry-source-file="LandingIPhone16.tsx" />
        <div className="my-10 rounded-xl bg-[#f1f2f4] p-8 text-center md:p-16">
          <div className="mb-4">
            <h4 className="mb-2 text-center text-2xl font-bold leading-tight md:text-4xl">
              นำเครื่องเก่ามาแลกเครื่องใหม่
            </h4>
            <div className="flex items-center justify-center">
              <span className="block text-center text-lg md:text-2xl">กับ</span>
              <Image alt="iPhone 16" height={400} src="/img/offers/Landingpage_offer_iPhone_16_Series_logo.png" width={400} data-sentry-element="Image" data-sentry-source-file="LandingIPhone16.tsx" />
            </div>
          </div>
          <Image alt="iPhone 16" height={500} src="/img/offers/Landingpage_offer_iPhone_16_Series_trade_in.png" width={1500} data-sentry-element="Image" data-sentry-source-file="LandingIPhone16.tsx" />
          <Link className="mx-auto mt-6 inline-block text-black" href="https://trade-in.uficon.com" target="_blank" data-sentry-element="Link" data-sentry-source-file="LandingIPhone16.tsx">
            <Button className="mx-auto h-auto bg-black px-4 py-2 text-white" data-sentry-element="Button" data-sentry-source-file="LandingIPhone16.tsx">
              เช็คราคา Trade In เบื้องต้น ได้ ที่นี่ คลิก <RightOutlined data-sentry-element="RightOutlined" data-sentry-source-file="LandingIPhone16.tsx" />
            </Button>
          </Link>
        </div>
        <div className="my-10">
          <h4 className="mb-4 text-center text-3xl font-bold leading-tight text-primary md:text-5xl">
            พิเศษสำหรับคุณ! <br />
            แลกซื้อสินค้าราคาพิเศษ
          </h4>
          <span className="block text-center text-xl md:text-3xl">
            เมื่อ Pre-Order iPhone 16 Series กับเรา
          </span>
        </div>
        <Image alt="iPhone 16" height={500} src="/img/offers/Landingpage_offer_iPhone_16_Series_Accy_Apple.png" width={1500} data-sentry-element="Image" data-sentry-source-file="LandingIPhone16.tsx" />
        <div className="my-10">
          <h4 className="mb-4 text-center text-3xl font-bold leading-tight text-primary md:text-5xl">
            เตรียมความพร้อมก่อนใคร! <br />
            อุปกรณ์เสริมราคาพิเศษ
          </h4>
          <span className="block text-center text-xl md:text-3xl">
            สำหรับลูกค้า Pre-Order iPhone 16 Series
          </span>
        </div>
        <Image alt="iPhone 16" height={500} src="/img/offers/Landingpage_offer_iPhone_16_Series_3rd_party.png" width={1500} data-sentry-element="Image" data-sentry-source-file="LandingIPhone16.tsx" />
        <div className="my-10 space-y-4 text-center">
          <h4 className="mb-4 text-center text-3xl font-bold leading-tight md:text-5xl">
            โปรโมชันพิเศษสำหรับผู้ถือบัตรเครดิตผ่อนชำระ
          </h4>
          <span className="mx-auto block w-fit bg-[#fdd200] px-3 text-xl font-bold md:text-3xl">
            ผ่อน 0% นานสูงสุด 36 เดือน*
          </span>
        </div>
        <Tabs centered className="bank-promotion" defaultActiveKey="1" items={items} data-sentry-element="Tabs" data-sentry-source-file="LandingIPhone16.tsx" />
      </div>
    </>;
}
const ProductOne = () => {
  const {
    t
  } = useTranslation();
  return <div className="grid gap-4 text-center" data-sentry-component="ProductOne" data-sentry-source-file="LandingIPhone16.tsx">
      <span className="text-lg text-red-600">ใหม่</span>
      <span className="text-4xl font-bold">iPhone 16</span>
      <span className="text-3xl">เร็วสุดแรง ฉลาดสุดล้ำ</span>
      <span className="block leading-tight">
        สั่งจองล่วงหน้า 13 ก.ย. 67 เวลา 19.00 น. <br /> วางจำหน่าย 20 ก.ย. 67
      </span>
      <span className="text-xl font-bold">เริ่มต้น ฿29,900</span>
      <Image alt="iPhone 16" className="mx-auto" height={240} src={"/img/products/iPhone_16.png"} width={800} data-sentry-element="Image" data-sentry-source-file="LandingIPhone16.tsx" />
      <div className="flex justify-center gap-2">
        <Link href={"/iphone"} data-sentry-element="Link" data-sentry-source-file="LandingIPhone16.tsx">
          <Button data-sentry-element="Button" data-sentry-source-file="LandingIPhone16.tsx">
            {t("preOrderButtonText")} <RightOutlined data-sentry-element="RightOutlined" data-sentry-source-file="LandingIPhone16.tsx" />
          </Button>
        </Link>
      </div>
    </div>;
};
const ProductTwo = () => {
  const {
    t
  } = useTranslation();
  return <div className="grid gap-4 text-center" data-sentry-component="ProductTwo" data-sentry-source-file="LandingIPhone16.tsx">
      <span className="text-lg text-red-600">ใหม่</span>
      <span className="text-4xl font-bold">iPhone 16 Pro</span>
      <span className="text-3xl">ทรงพลังอย่างล้ำลึก</span>
      <span className="block leading-tight">
        สั่งจองล่วงหน้า 13 ก.ย. 67 เวลา 19.00 น. <br /> วางจำหน่าย 20 ก.ย. 67
      </span>
      <span className="text-xl font-bold">เริ่มต้น ฿39,900</span>
      <Image alt="iPhone 16 Pro" className="mx-auto" height={240} src={"/img/products/iPhone_16_Pro.png"} width={800} data-sentry-element="Image" data-sentry-source-file="LandingIPhone16.tsx" />
      <div className="flex justify-center gap-2">
        <Link href={"/iphone"} data-sentry-element="Link" data-sentry-source-file="LandingIPhone16.tsx">
          <Button data-sentry-element="Button" data-sentry-source-file="LandingIPhone16.tsx">
            {t("preOrderButtonText")} <RightOutlined data-sentry-element="RightOutlined" data-sentry-source-file="LandingIPhone16.tsx" />
          </Button>
        </Link>
      </div>
    </div>;
};
export default LandingIPhone16;